import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import { useContacts } from "contexts/contacts";
import { useDebounce } from "hooks/debounce";

export default function Contact() {
  const [val, setVal] = useState<string>("");
  const [selected, setSelected] = useState<any | null>(null);
  const { control, setValue, watch } = useFormContext();
  const { searchedContacts, contacts, loading, refetch, setContactSearchText } =
    useContacts();

  const accountId = watch("accountId");
  const contactName = watch("contactName");
  const contactId = watch("contactId");

  const debouncedAccountId = useDebounce(accountId, 500);

  const [options, setOptions] = useState<
    {
      id: string;
      accountId: string;
      accountName: string;
      label: string;
    }[]
  >([]);

  useEffect(() => {
    if (accountId === "" || accountId === undefined || accountId === null) {
      setOptions(
        searchedContacts?.map((c) => ({
          id: c.id,
          accountId: c.accountId,
          accountName: `${c.account?.name} ${c.account?.site || ""}` || "",
          label: c.name || `${c.lastName} ${c.firstName}`,
        }))
      );
    } else {
      setOptions(
        contacts?.map((c) => ({
          id: c.id,
          accountId: c.accountId,
          accountName: `${c.account?.name} ${c.account?.site || ""}` || "",
          label: c.name || `${c.lastName} ${c.firstName}`,
        }))
      );
    }
  }, [contacts, accountId, searchedContacts]);

  useEffect(() => {
    refetch({ accountId: debouncedAccountId });
    const contactUpdate =
      contacts.length > 0 && contacts[0].accountId != debouncedAccountId;
    if (!contactUpdate) return;
    if (
      debouncedAccountId === "" ||
      debouncedAccountId === undefined ||
      debouncedAccountId === null
    ) {
      setValue("contactId", null);
      setValue("contactName", null);
      setSelected({ label: "", id: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAccountId]);

  useEffect(() => {
    if (!contactName) return;
    setSelected({ label: contactName, id: contactId });
  }, [contactName, contactId]);

  return (
    <Autocomplete
      options={options}
      isOptionEqualToValue={(option, value) =>
        !!value && option.id === value.id
      }
      inputValue={val}
      value={selected}
      onInputChange={(_, value) => {
        setContactSearchText(value || "");
        setVal(value || "");
        setSelected({ label: value });
      }}
      onChange={(_, value) => {
        if (value) {
          setValue("contactId", value.id);
          setValue("contactName", value.label);
          setValue("accountId", value.accountId);
          setValue("accountName", value.accountName);
        } else {
          setValue("contactId", null);
          setValue("contactName", null);
        }
      }}
      autoHighlight
      loading={loading}
      renderOption={(props, option) => (
        <li {...props}>
          {option.label} ({option.accountName})
        </li>
      )}
      renderInput={(params) => (
        <Controller
          render={({ field }) => (
            <TextField
              sx={{ width: "100%" }}
              label="取引先担当者"
              variant="outlined"
              required
              {...field}
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          control={control}
          name="contactId"
          defaultValue={contacts ? contacts[0]?.id : null}
          rules={{
            required: "必須項目です",
          }}
        />
      )}
    />
  );
}
